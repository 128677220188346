import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #f3f4f6;
  text-align: center;
  position: relative;
  padding: 20px;
  padding-left: 10%; /* Moves content more towards left */
`;

const Background = styled.div`
  position: absolute;
  inset: 0;
  background: url("/assets/rebranding.jpg") center/cover no-repeat;
  opacity: 0.3;
`;

const Content = styled.div`
  position: relative;
  z-index: 10;
  background: white;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
`;

const Text = styled.p`
  color: #555;
  margin-bottom: 24px;
`;

const Contact = styled.div`
  border-top: 1px solid #ddd;
  padding-top: 16px;
  color: #777;
  width: 100%;
  text-align: center;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const UnderMaintenance = () => {
  return (
    <Wrapper>
      <Background />
      <Content>
        <Title>We Are Upgrading</Title>
        <Text>
          CYLOGICS.com is currently under maintenance to bring you a better
          experience. We’ll be back soon!
        </Text>
        <Contact>
          <p>
            <strong>Contact Us</strong>
          </p>
          <p>
            Email:{" "}
            <Link href="mailto:hello@cylogics.com">hello@cylogics.com</Link>
          </p>
          <p>
            Phone: <Link href="tel:+917594999555">+91 7594 999 555</Link>
          </p>
        </Contact>
      </Content>
    </Wrapper>
  );
};

export default UnderMaintenance;
